<template>
    <div>
        <div style="margin: 5px 0;padding: 5px 0;">
            <!-- 新增配置的时候 -->
            <el-button type="text" @click="dialogFormVisibleSysFunc(true)">新增配置</el-button>
            <el-dialog title="新增配置" :visible.sync="dialogFormVisibleSys" :modal="false">
                <el-form :model="formSys">
                    <el-form-item label="配置名称" :label-width="formLabelWidth">
                        <el-input v-model="formSys.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="配置描述" :label-width="formLabelWidth">
                        <el-input v-model="formSys.desc" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="配置值" :label-width="formLabelWidth">
                        <el-input v-model="formSys.val" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisibleSysFunc(false)">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisibleFuncSysPush()">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 编辑更新配置的时候 -->
            <el-dialog title="更新配置" :visible.sync="dialogFormVisibleSysUpdate" :modal="false">
                <el-form :model="formSysUpdate">
                    <el-form-item label="配置名称" :label-width="formLabelWidth">
                        <el-input :value="formSysUpdate.name" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="配置描述" :label-width="formLabelWidth">
                        <el-input v-model="formSysUpdate.desc" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="配置值" :label-width="formLabelWidth">
                        <el-input v-model="formSysUpdate.val" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisibleSysUpdateFunc">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisibleFuncSysPut">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- 系统配置列表 -->
        <el-table :data="systemData" v-loading="loading" fit>
            <el-table-column fixed prop="id" label="ID编号"></el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column prop="name" label="配置名称"></el-table-column>
            <el-table-column prop="desc" label="配置描述"></el-table-column>
            <el-table-column prop="val" label="配置值"></el-table-column>

            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />
    </div>
</template>

<script>
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";
export default {
    components: {
        pageComponent,
    },
    data() {
        return {
            basePath: "api/v1/admin/system-list/",
            loading: true,
            totalCount: 0,
            total: 0,
            currentPage: 1,
            searchSysVal: "", // 搜索
            systemData: [], // 系统配置列表

            dialogFormVisibleSys: false, // 新增
            formLabelWidth: "120px",
            formSys: {
                name: '',
                desc: '',
                val: ''
            },

            dialogFormVisibleSysUpdate: false, // 更新
            formSysUpdate: {
                name: '',
                desc: '',
                val: ''
            },


        }
    },

    created() {
        this.getSysData(this.currentPage);
    },
    watch: {
        currentPage(newPage) {
            this.getSysData(newPage);
        },
    },

    methods: {
        // 初始化获取数据
        getSysData(page) {
            this.loading = true
            this.$get(this.basePath, {
                pageNum: page,
                search: this.searchUserVal,
            }).then(
                (res) => {
                    this.systemData = res.data;
                    this.totalCount = res.count;
                    this.total = res.total;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 系统配置-新增
        dialogFormVisibleSysFunc(showTK) {
            this.formSys = {}
            this.dialogFormVisibleSys = showTK
        },
        // 系统配置-确认
        dialogFormVisibleFuncSysPush() {
            this.$post(this.basePath, this.formSys).then(
                (res) => {
                    this.$msgSuccess()
                    this.getSysData()
                    this.formSys = {}
                    this.dialogFormVisibleSys = false
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 点击编辑
        handleEdit(scope, row) {
            this.formSysUpdate = row
            this.dialogFormVisibleSysUpdate = true
        },
        // 取消编辑
        dialogFormVisibleSysUpdateFunc() {
            this.dialogFormVisibleSysUpdate = false
        },
        // 确认提交
        dialogFormVisibleFuncSysPut() {
            this.$put(this.basePath, this.formSysUpdate).then(res => {
                this.$msgSuccess()
                this.getSysData(this.currentPage)
                this.dialogFormVisibleSysUpdateFunc()
            }, err => {
                this.$msgError(err.msg)
            })
        },
    }
}
</script>
