<template>
    <div class="container">
        <headerVue />

        <!-- 個人信息 -->
        <div class="user-profile">
            <div class="header">
                <img src="../../../assets/images/img_login.png" class="profile-image">
                <div class="user-info">
                    <div style="font-weight: 800;color: #5fce73;">
                        {{ userInfo.nickname }}
                        <img :src="userInfo.isVipImg" style="width: 25px;height: 25px;vertical-align: sub;">
                    </div>
                    <el-button type="text">是否會員:{{ userInfo.isVip == 1 ? "是" : "否" }}</el-button>
                    <div style="font-weight: 800;color: #5fce73;" v-if="userInfo.isVip == 1">
                        到期時間: {{ userInfo.vipEnd }}
                    </div>
                </div>
            </div>

            <!-- <div class="balance">
                <div>到期時間: {{ userInfo.vipEnd }}</div>
            </div> -->

            <div class="stats">
                <div>
                    <i class="el-icon-user"></i>
                    <span>賬號: {{ userInfo.nickname }}</span>
                </div>
                <div @click="$copyText(userInfo.coupon)">
                    <i class="el-icon-view"></i>
                    <span>優惠碼: {{ userInfo.coupon }}</span>
                </div>
                <div style="color: red;">
                    <i class="el-icon-message"></i>
                    <span>{{ userInfo.couponMsg ? userInfo.couponMsg : '優惠碼描述' }}</span>
                </div>
                <div style="color: red;">
                    <i class="el-icon-question"></i>
                    <span>點擊優惠碼即可複製分享</span>
                </div>
            </div>
        </div>

        <!-- 訂單信息 -->
        <div style="width: 90%;margin: auto;" v-loading="loading">
            <div class="personalTitle">訂單信息</div>
            <el-table :data="orderData" fit style="border-radius: 8px; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);">
                <el-table-column :min-width="columnWidth" prop="createTime" label="日期"></el-table-column>
                <el-table-column :min-width="columnWidth" prop="orderType" label="狀態"></el-table-column>
                <el-table-column :min-width="columnWidth" prop="vipName" label="名稱"></el-table-column>
                <el-table-column :min-width="columnWidth" prop="vipPrice" label="價格"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";

export default {
    name: 'UserProfile',
    components: {
        headerVue,
    },
    data() {
        return {
            userInfo: {}, // 用户个人信息
            loading: true,
            orderData: [], // 订单信息
        };
    },
    computed: {
        columnWidth() {
            return window.innerWidth > 768 ? '180' : '';
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
        this.getPersonalFunc();
        this.getPersonalOrderFunc()
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        // 获取用户个人信息
        getPersonalFunc() {
            this.$post("api/v1/personal/").then(
                (res) => {
                    this.userInfo = res.data;
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 获取用户订单信息
        getPersonalOrderFunc() {
            this.$post("api/v1/orders/").then(
                (res) => {
                    this.orderData = res.data
                    this.loading = false
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 强制组件重新渲染
        onResize() {
            this.$forceUpdate();
        }

    },
}
</script>

<style scoped>
.user-profile {
    padding: 10px;
    background-color: #fff;
    width: 85%;
    margin: auto;
    border-radius: 10px 0 10px 0;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;
}

.balance {
    align-items: center;
    margin-bottom: 20px;

}

.books {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.book-item img {
    width: 100%;
    height: auto;
}

.stats div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.stats i {
    margin-right: 5px;
}

.personalTitle {
    font-weight: 800;
    margin: 10px 0;
    color: #1669f3;
}
</style>