<template>
    <div class="container">
        <headerVue />

        <videoPlayer />

        <!-- footer -->
        <footerComponent />
    </div>
</template>

<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
import videoPlayer from "../../componentVue/videoPlayer/videoPlayer.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";

export default {
    components: {
        headerVue,
        videoPlayer,
        footerComponent,
    },
}
</script>
