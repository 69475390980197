<template>
  <div class="containerLogin">
    <div class="image-section">
      <img src="../../../assets/images/img_login.png" class="bgImg">
      <div class="back-home" @click="backHome">
        <div class="bh-div">
          <img sizes="100vh" class="bh-index" height="33" width="100" alt="" src="../../../assets/images/back_home.png">
          <div class="bh-back">返回主页</div>
        </div>
      </div>
    </div>

    <div class="login-section">
      <input v-model="nickname" type="text" placeholder="输入用户名" @keyup.enter="login" />
      <input v-model="password" type="password" placeholder="输入密码" @keyup.enter="login" />
      <span style="color: red;font-size: 12px;">
        <i class="el-icon-question"></i>登錄即表示同意註冊使用
      </span>
      <button @click="login">登录</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nickname: '',
      password: ''
    };
  },
  methods: {
    login() {
      this.$post("api/v1/login/", {
        nickname: this.nickname,
        password: this.password
      }).then(res => {
        this.$msgSuccess('登錄成功');
        window.sessionStorage.setItem("user", res.author);
        window.sessionStorage.setItem("token", res.token);
        window.sessionStorage.setItem("auth", res.auth);
        this.$router.push({ path: "/" });
      }, err => {
        this.$msgError(err.msg);
      })
    },
    backHome() {
      this.$router.push({ path: "/" });
    }
  }
}
</script>

<style scoped>
.containerLogin {
  display: flex;
  height: 100vh;
  align-items: stretch;
  overflow: hidden;
}

.image-section {
  flex: 1;
  position: relative;
}

.image-section .bgImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.back-home {
  object-fit: cover;
  position: absolute;
  top: 20px;
  width: 100%;
}

.back-home .bh-div {
  float: left;
  position: relative;
  rotate: 180deg;
}

.back-home .bh-index {
  object-fit: cover;
  cursor: pointer;
}

.back-home .bh-back {
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  left: -6p;
  left: 23px;
  position: absolute;
  rotate: 180deg;
  top: 6px;
}

.login-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  border-radius: 20px 20px 0 0;
  /* 添加圆角 */
  background: #f5ead9;
  /* 添加背景色 */
  padding: 50px 0;
  z-index: 1;
}

/* 输入框样式 */
.login-section input {
  width: 80%;
  margin: 10px;
  padding: 10px;
  border: 2px solid #fda085;
  /* 自定义边框颜色 */
  border-radius: 8px;
  /* 圆角边框 */
  outline: none;
  /* 去除聚焦时的默认外轮廓 */
  transition: border-color 0.3s, box-shadow 0.3s;
  /* 过渡动画 */
}

.login-section input:focus {
  border-color: #f6d365;
  /* 聚焦时改变边框颜色 */
  box-shadow: 0 0 8px rgba(246, 211, 101, 0.8);
  /* 添加聚焦阴影 */
}

.login-section input::placeholder {
  color: #ccc;
  /* 占位符颜色 */
  font-style: italic;
  /* 斜体字样式 */
}


.login-section button {
  width: 84%;
  padding: 10px;
  background-image: linear-gradient(to right, #f596bd 0%, #d9bfd9 100%);
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 8px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .containerLogin {
    flex-direction: column;
  }

  .image-section,
  .login-section {
    flex: none;
    width: 100%;
  }

  .image-section .bgImg {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 200px;
    order: -1;
  }
}
</style>