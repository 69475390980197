<template>
  <div class="container">
    <headerVue />
    <carouselChart />
    <!-- marqueeText="" -->
    <marqueeVue :marqueeText="marqueeText" />
    <freeComponent isType="free" isSize="8" isTitle="免費放映" :isShowMore="true" />
    <freeComponent isType="hot" isSize="15" isTitle="熱門放映" :isShowMore="true" />
    <footerComponent />
    <!-- scrollTop -->
    <scrollTop :scrollDom="'.container'"></scrollTop>
  </div>
</template>

<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
import carouselChart from "../../componentVue/carouselChart/carouselChart.vue";
import marqueeVue from "../../componentVue/marqueeComponent/marqueeComponent.vue";
import freeComponent from "../../componentVue/freeComponent/freeComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue";

export default {
  components: {
    headerVue,
    carouselChart,
    marqueeVue,
    freeComponent,
    footerComponent,
    scrollTop
  },
  data() {
    return {
      marqueeText: ""
    }
  },
  created() {
    // remove page num
    window.sessionStorage.removeItem("allPagehot")
    window.sessionStorage.removeItem("allPagefree")
    window.sessionStorage.removeItem("allPageisZh")
    window.sessionStorage.removeItem("allPageisCN")
    window.sessionStorage.removeItem("allPageisAi")
    window.sessionStorage.removeItem("allPageisZb")
    window.sessionStorage.removeItem("allPageisSr")
    window.sessionStorage.removeItem("allPageisTh")
    window.sessionStorage.removeItem("allPageisJr")
    window.sessionStorage.removeItem("allPageisHs")
    this.marqueeText = window.sessionStorage.getItem("notice")
  },
};
</script>

<style scoped></style>
