<template>
    <div title="回到顶部" class="comp-to-top" @click="scrollToTop" v-show="toTopShow">TOP</div>
</template>

<script>
export default {
    props: {
        scrollDom: {
            type: String,
            default: ".content-container"
        }
    },
    data() {
        return {
            dom: null,
            toTopShow: false,
            scrollTop: ""
        };
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener("scroll", this.handleScroll, true);
        });
    },
    destroyed() {
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods: {
        handleScroll() {
            this.dom = document.querySelector(this.scrollDom);
            this.scrollTop = this.dom.scrollTop;
            if (this.scrollTop > 100) {
                this.toTopShow = true;
            } else {
                this.toTopShow = false;
            }
        },
        scrollToTop() {
            let timer = null;
            let _this = this;
            //动画，使用requestAnimationFrame代替setInterval
            cancelAnimationFrame(timer);
            timer = requestAnimationFrame(function fn() {
                if (_this.scrollTop > 1000) {
                    _this.scrollTop -= 1000;
                    _this.dom.scrollTop = _this.scrollTop;
                    timer = requestAnimationFrame(fn);
                } else if (_this.scrollTop > 100 && _this.scrollTop <= 1000) {
                    _this.scrollTop -= 100;
                    _this.dom.scrollTop = _this.scrollTop;
                    timer = requestAnimationFrame(fn);
                } else if (_this.scrollTop > 10 && _this.scrollTop <= 100) {
                    _this.scrollTop -= 10;
                    _this.dom.scrollTop = _this.scrollTop;
                    timer = requestAnimationFrame(fn);
                } else if (_this.scrollTop > 0 && _this.scrollTop <= 10) {
                    _this.scrollTop -= 2;
                    _this.dom.scrollTop = _this.scrollTop;
                    timer = requestAnimationFrame(fn);
                } else {
                    cancelAnimationFrame(timer);
                    _this.toTopShow = false;
                }
            });
        }
    }
};
</script>
<style scoped>
.comp-to-top {
    background-color: #4aa557;
    position: fixed;
    right: 20px;
    bottom: 80px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-weight: 800;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    z-index: 100;

    &:hover {
        opacity: 1;
    }
}
</style>
