<template>
    <div class="container">
        <headerVue />

        <div class="adminBox">
            <el-menu :default-active="path" class="el-menu-demo" mode="horizontal" text-color="black"
                background-color="white" active-text-color="#31c27c" router>
                <!-- 使用v-for循环添加子菜单 -->
                <template v-for="(item, index) in nav_menu_data">
                    <el-menu-item :index="item.path">
                        {{ item.title }}
                    </el-menu-item>
                </template>
            </el-menu>
            <!-- 子菜单页面 -->
            <router-view />
        </div>

        <footerComponent />

        <!-- scrollTop -->
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>
<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue";

export default {
    components: {
        headerVue,
        footerComponent,
        scrollTop
    },
    data() {
        return {
            nav_menu_data: [
                {
                    title: "用户列表",
                    path: "/admin/userList",
                },
                {
                    title: "订单列表",
                    path: "/admin/orderList",
                },
                {
                    title: "系统配置",
                    path: "/admin/systemList",
                },
                {
                    title: "爬虫账号",
                    path: "/admin/spiderList",
                },
                {
                    title: "第三方账号",
                    path: "/admin/thirdPayList",
                },
                {
                    title: "加密解密值",
                    path: "/admin/encryptAES",
                },
            ],
        };
    },

    created() {
        this.onRouteChanged();
    },
    watch: {
        $route: "onRouteChanged",
    },
    methods: {
        onRouteChanged() {
            let that = this;
            that.path = that.$route.path;
        },
    },
};

</script>

<style>
.adminBox {
    width: 90%;
    margin: auto;
}
</style>
