<template>
    <div class="container">
        <headerVue :isSearchPage="true" />

        <div style="margin: auto;width: 90%;margin-bottom: 10px;">
            <el-input placeholder="请输入内容" v-model="searchVal" class="input-with-select" clearable>
                <el-button slot="append" icon="el-icon-search" @click="searchFunc"></el-button>
            </el-input>
        </div>

        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount"
            @change="handlePageChange" />

        <!-- 搜索信息 -->
        <div class="searchBox" v-loading="loading">
            <div v-for="(items, index) in allList" :key="index" class="item" @click="toActor(items)">
                <div class="image-container">
                    <img :src="getAvatarUrl(items.aid)" class="avatar-image" :alt="items.name"
                        @load="handleImageLoad(items)" @error="handleImageError">
                    <div class="loading-spinner" v-if="!items.imageLoaded"></div>
                </div>
                <div class="item-name">{{ items.name }}</div>
            </div>
        </div>

        <footerComponent />

        <!-- scrollTop -->
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
import scrollTop from "../../componentVue/scrollComponent/scrollComponent.vue"
export default {
    components: {
        headerVue,
        pageComponent,
        footerComponent,
        scrollTop
    },
    data() {
        return {
            allList: [],
            totalCount: 0,
            total: 0,
            currentPage: 1,
            enteredPage: 1,
            searchVal: null,
            loading: true,
            errSrc: ""
        };
    },
    watch: {
        currentPage(newPage) {
            this.initDataFunc(newPage);
        },
    },
    mounted() {
        this.initDataFunc(this.currentPage);
    },

    methods: {
        initDataFunc(page) {
            // 初始化數據列表
            this.$post("api/v1/search/", {
                searchVal: this.searchVal,
                pageNum: page,
            }).then(
                (res) => {
                    this.allList = res.data.map(item => ({ ...item, imageLoaded: false }));
                    this.totalCount = res.count;
                    this.total = res.total;
                    this.errSrc = res.errSrc
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 輸入搜索詞
        searchFunc() {
            this.currentPage = 1 ?? this.currentPage != 1
            this.initDataFunc(this.currentPage);
        },

        getAvatarUrl(aid) {
            return `https://img.kankua.xyz/static/avatar_1eTTDXdRKSjzSNkz/${aid}.png`;
        },

        handleImageLoad(item) {
            item.imageLoaded = true;
        },

        handleImageError(event) {
            // event.target.src = 'https://img.kankua.xyz/static/avatar_1eTTDXdRKSjzSNkz/000000.png';
            event.target.src = this.errSrc;
        },

        handlePageChange() {
            window.scrollTo(0, 0); // 回到页面顶部
        },

        toActor(item) {
            this.$router.push({ name: "actor", params: { name: item.name } });
        }
    },
};
</script>

<style scoped>
.searchBox {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    margin: auto;
    justify-content: center;
}

.item {
    width: 19%;
    /* 100% / 5 */
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    background: #f6f7fa;
    margin: 3px;
    transition: transform 0.2s;
}

/* .item:hover {
    transform: scale(1.05);
} */

.image-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto;
}

.avatar-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    /* 确保图片按比例裁剪 */
    border-radius: 50%;
    /* 使图片圆形 */
}

.loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.item-name {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
}

@media (max-width: 900px) {
    .item {
        width: 33.33%;
        /* 100% / 3 */
    }
}

@media (max-width: 600px) {
    .item {
        width: 48%;
        /* 100% / 2 */
    }
}
</style>