<template>
    <div>
        <div style="margin: 5px 0;padding: 5px 0;">
            <!-- 新增账号的时候 -->
            <el-button type="text" @click="dialogFormVisibleSysFunc(true)">新增账号</el-button>
            <el-button type="text" @click="updateFreeVideoFunc">更新免费视频</el-button>

            <!-- 新增账号弹窗 -->
            <el-dialog title="新增账号" :visible.sync="dialogFormVisibleSys" :modal="false" v-loading="loading">
                <el-form :model="formSys">
                    <el-form-item label="账号" :label-width="formLabelWidth">
                        <el-input v-model="formSys.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth">
                        <el-input v-model="formSys.pwd" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="账号状态" :label-width="formLabelWidth">
                        <el-select v-model="formSys.isVip" placeholder="请选择账号状态">
                            <el-option label="会员账号" value="1"></el-option>
                            <el-option label="普通账号" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisibleSysFunc(false)">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisibleFuncSysPush()">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 编辑更新配置的时候 -->
            <el-dialog title="更新账号" :visible.sync="dialogFormVisibleSysUpdate" :modal="false" v-loading="loading">
                <el-form :model="formSysUpdate">
                    <el-form-item label="账号" :label-width="formLabelWidth">
                        <el-input :value="formSysUpdate.name" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth">
                        <el-input v-model="formSysUpdate.pwd" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="账号状态" :label-width="formLabelWidth">
                        <el-select v-model="formSysUpdate.isVip" placeholder="请选择账号状态">
                            <el-option label="会员账号" value="1"></el-option>
                            <el-option label="普通账号" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否可用" :label-width="formLabelWidth">
                        <el-select v-model="formSysUpdate.isNormal" placeholder="请选择是否可用">
                            <el-option label="是" value="1"></el-option>
                            <el-option label="否" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisibleSysUpdateFunc">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisibleFuncSysPut">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <!-- 爬虫账号列表 -->
        <el-table :data="spiderAuthData" v-loading="loading" fit>
            <el-table-column fixed prop="id" label="ID编号"></el-table-column>
            <el-table-column prop="updateAt" label="更新时间"></el-table-column>
            <el-table-column prop="name" label="账号"></el-table-column>
            <el-table-column prop="pwd" label="密码"></el-table-column>
            <el-table-column prop="isNormal" label="账号状态"
                :formatter="(row) => (row.isNormal === 1 ? '正常' : '封禁')"></el-table-column>
            <el-table-column prop="isVip" label="是否会员"
                :formatter="(row) => (row.isVip === 1 ? '是会员' : '非会员')"></el-table-column>

            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" @click="checkAuthFunc(scope.$index, scope.row)">验证</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />
    </div>
</template>

<script>
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";
export default {
    components: {
        pageComponent,
    },
    data() {
        return {
            basePath: "api/v1/admin/spider-auth-list/",
            baseCheckAuthPath: "api/v1/admin/spider-auth-check/",
            loading: true,
            totalCount: 0,
            total: 0,
            currentPage: 1,
            searchSysVal: "", // 搜索
            spiderAuthData: [], // 爬虫账号列表

            dialogFormVisibleSys: false, // 新增
            formLabelWidth: "120px",
            formSys: {
                name: '',
                pwd: '',
                isVip: 0
            },

            dialogFormVisibleSysUpdate: false, // 更新
            formSysUpdate: {},


        }
    },

    created() {
        this.getSpiderAuthData(this.currentPage);
    },
    watch: {
        currentPage(newPage) {
            this.getSpiderAuthData(newPage);
        },
    },

    methods: {
        // 初始化获取数据
        getSpiderAuthData(page) {
            this.loading = true
            this.$get(this.basePath, {
                pageNum: page,
                search: this.searchUserVal,
            }).then(
                (res) => {
                    this.spiderAuthData = res.data;
                    this.totalCount = res.count;
                    this.total = res.total;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 爬虫账号-新增
        dialogFormVisibleSysFunc(showTK) {
            this.formSys = { isVip: 0 }
            this.dialogFormVisibleSys = showTK
        },
        // 爬虫账号-确认
        dialogFormVisibleFuncSysPush() {
            this.loading = true
            this.$post(this.basePath, this.formSys).then(
                (res) => {
                    this.$msgSuccess()
                    this.getSpiderAuthData()
                    this.formSys = { isVip: 0 }
                    this.dialogFormVisibleSys = false
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },


        // 点击编辑
        handleEdit(scope, row) {
            this.formSysUpdate = row
            this.dialogFormVisibleSysUpdate = true
        },
        // 取消编辑
        dialogFormVisibleSysUpdateFunc() {
            this.dialogFormVisibleSysUpdate = false
        },
        // 确认提交
        dialogFormVisibleFuncSysPut() {
            this.loading = true
            this.$put(this.basePath, this.formSysUpdate).then(res => {
                this.$msgSuccess()
                this.getSpiderAuthData(this.currentPage)
                this.dialogFormVisibleSysUpdateFunc()
            }, err => {
                this.$msgError(err.msg)
            })
        },

        // 爬虫账号检查是否有效
        checkAuthFunc(scope, row) {
            this.loading = true
            this.$post(this.baseCheckAuthPath, row).then((res) => {
                this.$msgSuccess(res.data)
                this.loading = false
            }, (err) => {
                this.$msgError(err.msg);
            }
            );
        },

        // 更新free视频
        updateFreeVideoFunc() {
            this.$alert('是否确认更新视频', '更新免费视频', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        this.$post("api/v1/admin/update-free-video/").then(res => {
                            this.$msgSuccess()
                        }, err => {
                            this.$msgError(err.msg)
                        })
                    }
                }
            });
        },
    }
}
</script>
