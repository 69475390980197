<template>
  <div class="footer">{{ footerStr }}</div>
</template>

<script>
export default {
  name: "footerComponent",
  props: {
    footerStr: {
      type: String,
      default: "暫無更多"
    },
  },
};
</script>

<style scoped>
.footer {
  margin: 30px;
  text-align: center;
  font-size: 16px;
}
</style>
