<template>
  <div class="detailBox">
    <el-carousel indicator-position="outside" class="carousel" arrow="always" v-loading="loading">
      <el-carousel-item v-for="(image, index) in images" :key="index">
        <img :src="image.img" :alt="'Image ' + (index + 1)" class="cardImg" @click="toActorFunc(image.aid)" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>

export default {
  name: "carouselChart",
  data() {
    return {
      loading: true,
      images: [],
      currentIndex: 0,
    };
  },
  mounted() {
    this.carouselChartData();
  },
  methods: {
    carouselChartData() {
      this.$get("api/v1/carouselChart/").then(res => {
        this.images = res.data;
        this.loading = false
        window.sessionStorage.setItem("notice", res.notice)
      }, err => {
        this.$msgError(err.msg)
      })
    },
    toActorFunc(aid) {
      this.$post("api/v1/carouselChart/", { aid }).then(res => {
        const name = res.name
        this.$router.push({ name: "actor", params: { name } });
      }, err => {
        this.$msgError(err.msg)
      })
    },
  },
};
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

/* 在PC端，carousel和cardImg的高度为350px */
.carousel::v-deep .el-carousel__container,
.cardImg {
  object-fit: cover;
  width: 100%;
  height: 450px;
  border-radius: 5px;
}

@media (max-width: 600px) {

  /* 在手机端，carousel和cardImg的高度为200px */
  .carousel::v-deep .el-carousel__container,
  .cardImg {
    height: 150px;
  }
}
</style>
