<template>
    <div>
        <div class="baseBox">
            <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 25 }" placeholder="请输入需要加密或解密的内容"
                v-model="inpVal">
            </el-input>
        </div>
        <el-row>
            <el-button type="primary" @click="aesFunc('encrypt')">加密</el-button>
            <el-button type="success" @click="aesFunc('decrypt')">解密</el-button>
        </el-row>
        <div class="baseBox decryptBox">{{ decryptRes }}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            basePath: "api/v1/admin/encrypt-aes/",
            inpVal: "",
            decryptRes: "加密或解密后的值",
        }
    },
    methods: {
        aesFunc(isType) {
            this.$post(this.basePath, {
                isType,
                inpVal: this.inpVal
            }).then(res => {
                this.decryptRes = res.data
                this.inpVal = res.data
            }, err => {
                this.$msgError(err.msg)
            })
        },
    }
}
</script>

<style>
.baseBox {
    margin: 5px 0;
    padding: 5px 0;
}

.decryptBox {
    border: 1px solid #0dbc79;
    height: 150px;
    background: #fff;
    border-radius: 5px;
    padding: 5px;
    word-wrap: break-word;
    overflow: auto;
}
</style>