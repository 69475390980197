<template>
    <div>
        <div style="margin: 5px 0;padding: 5px 0;">
            <el-input placeholder="请输入用户ID或名称" v-model="searchUserVal" class="input-with-select" clearable>
                <el-button slot="append" icon="el-icon-search" @click="getUserData(1)"></el-button>
            </el-input>
        </div>
        <!-- 用户列表 -->
        <el-table :data="userData" v-loading="loading">
            <el-table-column fixed prop="nickname" label="姓名"></el-table-column>
            <el-table-column prop="id" label="ID编号"></el-table-column>
            <el-table-column prop="ipaddr" label="IP"></el-table-column>
            <el-table-column prop="ip_loc" label="IP地址"></el-table-column>
            <el-table-column prop="normal" label="账号状态"
                :formatter="(row) => (row.normal === 1 ? '正常' : '封禁')"></el-table-column>
            <el-table-column prop="isVip" label="是否会员"
                :formatter="(row) => (row.isVip === 1 ? '是会员' : '非会员')"></el-table-column>
            <el-table-column prop="vipStart" label="会员开始时间"></el-table-column>
            <el-table-column prop="vipEnd" label="会员到期时间"></el-table-column>

            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 点击编辑的弹框修改 -->
        <el-dialog title="修改用户信息" :visible.sync="dialogFormVisibleSys" :modal="false">
            <el-form :model="formSys">
                <el-form-item label="账号名" :label-width="formLabelWidth">
                    <el-input :disabled="true" :value="formSys.nickname"></el-input>
                </el-form-item>
                <el-form-item label="是否会员" :label-width="formLabelWidth">
                    <el-input :disabled="true" :value="formSys.isVip"></el-input>
                </el-form-item>
                <el-form-item label="账号状态" :label-width="formLabelWidth">
                    <el-select v-model="formSys.normal" placeholder="请选择账号状态">
                        <el-option label="正常" value="1"></el-option>
                        <el-option label="封禁" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="会员天数" :label-width="formLabelWidth">
                    <el-input v-model="isVipNum" autocomplete="off" placeholder="给多少天的会员"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelEdit(false)">取 消</el-button>
                <el-button type="primary" @click="confirmEdit()">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 分页器 -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />
    </div>
</template>

<script>
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";
export default {
    components: {
        pageComponent,
    },
    data() {
        return {
            basePath: "api/v1/admin/user-list/",
            loading: true,
            totalCount: 0,
            total: 0,
            currentPage: 1,
            searchUserVal: "", // 搜索
            userData: [], // 用户信息

            dialogFormVisibleSys: false, // 编辑弹框
            formLabelWidth: "120px",
            selectedUser: "", // 被点击的用户名
            formSys: {
                nickname: "",
                normal: '',
                isVip: '',
            },
            isVipNum: 0, // 多少天的会员
        }
    },

    created() {
        this.getUserData(this.currentPage);
    },
    watch: {
        currentPage(newPage) {
            this.getUserData(newPage);
        },
    },

    methods: {
        // 初始化获取数据
        getUserData(page) {
            this.loading = true
            this.$get(this.basePath, {
                pageNum: page,
                search: this.searchUserVal,
            }).then(
                (res) => {
                    this.userData = res.data;
                    this.totalCount = res.count;
                    this.total = res.total;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 编辑按钮
        handleEdit(scope, row) {
            this.formSys = row
            this.dialogFormVisibleSys = true
        },
        // 取消编辑
        cancelEdit() {
            this.dialogFormVisibleSys = false
        },
        // 确认编辑
        confirmEdit() {
            this.$set(this.formSys, 'isVipNum', this.isVipNum)
            this.$post(this.basePath, this.formSys).then(res => {
                this.$msgSuccess()
                this.dialogFormVisibleSys = false
                this.getUserData(this.currentPage);
            }, err => {
                this.$msgError(err.msg)
            })
        },
    }
}
</script>
