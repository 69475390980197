<template>
  <div class="detailBox" v-loading="loading">
    <div class="freeTitle" @click="handleClickMore(isType)">
      <span><i class="el-icon-menu"></i>{{ isTitle }}</span>
      <span style="float: right;">更多</span>
    </div>
    <imageList :allList="images" :loading="loading" :showFree="isType" :isShowMore="isShowMore" />
    <el-empty description="暫無更多" v-if="images.length == 0"></el-empty>
  </div>
</template>

<script>
import imageList from "../imageListComponent/imageListComponent.vue"
export default {
  name: "freeComponent",
  components: {
    imageList,
  },
  props: {
    isType: String,
    isSize: String,
    isTitle: String,
    isShowMore: Boolean
  },
  data() {
    return {
      loading: true,
      images: [],
    };
  },
  mounted() {
    this.getFreeInfo();
  },
  methods: {
    async getFreeInfo() {
      this.loading = true
      let propsVal = this.$props;
      this.$get("api/v1/freeInfo/", {
        type: propsVal.isType,
        pageSize: propsVal.isSize
      }).then(res => {
        this.images = res.data;
        this.loading = false
      }, err => {
        this.$msgError(err.msg)
      })
    },
    handleClickMore(t) {
      this.$router.push({ name: "allPage", params: { t } });
    },
  },
};
</script>

<style scoped>
.freeTitle {
  margin: 5px 0;
  color: #1669f3;
  font-size: 16px;
}
</style>
