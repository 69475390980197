<template>
    <div>
        <!-- 筛选订单 -->
        <el-row style="margin-bottom: 5px 0">
            <el-dropdown style="margin: 5px 0" trigger="click">
                <el-button type="primary">
                    更多筛选<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in elDropDownItem" :key="item.id"
                        @click.native="showOrderFunc(item.en)">
                        {{ item.name }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- 查看金额 -->
            <el-dropdown style="margin: 5px" trigger="click">
                <el-button type="danger">
                    查看金额<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in elDropDownItemDay" :key="item.id"
                        @click.native="showOrderPayNumFunc(item.en)">
                        {{ item.name }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button style="margin: 5px" type="success" v-loading="loading">总金额:{{ payTotalNum
                }}</el-button>
        </el-row>
        <!-- 订单列表 -->
        <el-table :data="orderData" v-loading="loading">
            <!-- <el-table-column fixed prop="orderId" label="订单ID"></el-table-column> -->
            <el-table-column prop="createTime" label="支付日期"></el-table-column>
            <el-table-column prop="nickname" label="购买账号"></el-table-column>
            <el-table-column prop="orderType" label="支付状态"></el-table-column>
            <el-table-column prop="vipName" label="会员名称"></el-table-column>
            <el-table-column prop="vipDesc" label="会员详情"></el-table-column>
            <el-table-column prop="vipPrice" label="会员价格"></el-table-column>

            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />
    </div>
</template>

<script>
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";
export default {
    components: {
        pageComponent,
    },
    data() {
        return {
            basePath: "api/v1/admin/order-list/",
            baseGetDropDownPath: "/api/v1/admin/el_dropdown_settings/",
            loading: true,
            totalCount: 0,
            total: 0,
            currentPage: 1,
            orderData: [], // 订单信息

            searchOrderVal: "all", // 查看所有订单
            payTotalNum: 0, // 订单总金额
            searchDay: "all", // 获取金额 默认所有
            elDropDownItem: [], // 下拉筛选
            elDropDownItemDay: [], // 下拉筛选 根据时间获取金额
        }
    },

    created() {
        this.getOrderData(this.currentPage);
        this.getOrderPayNum();
    },
    watch: {
        currentPage(newPage) {
            this.getOrderData(newPage);
        },
    },

    methods: {
        // 获取下拉筛选的数据
        getElDropDownItems(name) {
            this.$post(this.baseGetDropDownPath, {
                search: name,
            }).then(
                (res) => {
                    this.elDropDownItem = res.data;
                    this.elDropDownItemDay = res.daysItem;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 初始化获取数据
        getOrderData(page) {
            this.loading = true
            this.$get(this.basePath, {
                pageNum: page,
                search: this.searchOrderVal,
            }).then(
                (res) => {
                    this.orderData = res.data;
                    this.totalCount = res.count;
                    this.total = res.total;
                    this.loading = false;
                    this.getElDropDownItems("order");
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 获取订单金额
        getOrderPayNum() {
            this.loading = true;
            this.$post(this.basePath, {
                searchDay: this.searchDay,
            }).then(
                (res) => {
                    this.payTotalNum = res.sumTotal;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 重置分页信息
        resetPageData() {
            (this.totalCount = 0), (this.total = 0), (this.currentPage = 1);
        },

        // 点击查看某种状态的订单
        showOrderFunc(searchVal) {
            this.searchOrderVal = searchVal;
            this.resetPageData();
            this.getOrderData(this.currentPage);
        },

        // 查看金额
        showOrderPayNumFunc(searchVal) {
            this.searchDay = searchVal;
            this.getOrderPayNum();
        },

        // 编辑按钮
        handleEdit(scope, row) {
            console.log(row);
        },
    }
}
</script>
