<template>
  <div>
    <el-container class="home-container">
      <el-header>
        <div>
          <!-- <el-link v-for="(item, index) in links" :key="index" class="backBtnOrHome" :underline="false"
            @click="switchswitchHandle(item.action)" :icon="item.icon || ''">
            {{ item.text }}
          </el-link> -->

          <el-link class="backBtnOrHome" :underline="false" v-if="$route.path !== '/'"
            @click="switchswitchHandle('goBack')">返回</el-link>
          <el-link class="backBtnOrHome" :underline="false" @click="switchswitchHandle('goHome')">首頁</el-link>
          <el-link class="backBtnOrHome" :underline="false" @click="switchswitchHandle('toSearch')">搜索</el-link>
          <el-dropdown trigger="click">
            <span class="el-avatar el-dropdown-link">
              {{ selectName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in links" :key="index"
                @click.native="switchswitchHandle(item.action, item.text)">
                {{ item.text }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- user login show tar -->
        <div>
          <div v-if="!isLogin" @click="goToLogin" style="font-size: 14px;"><i class="el-icon-user"></i>登錄</div>
          <el-dropdown v-else trigger="click">
            <span class="el-avatar el-dropdown-link">
              {{ userName }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in dropdownItem" :key="index"
                @click.native="switchDropHandle(item.action)">
                {{ item.text }}
              </el-dropdown-item>
              <!-- <el-dropdown-item @click.native="switchDropHandle('goMyCollFunc')">
                我的收藏
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "headerVue",
  data() {
    return {
      links: [],
      isLogin: false,
      userName: "",
      dropdownItem: [],
      selectName: "OxO"
    };
  },
  props: {
    isSearchPage: Boolean,
  },
  mounted() {
    this.isHomePage = this.$route.path === "/";
    this.getInitHeader();
    const token = window.sessionStorage.getItem("token");
    this.isLogin = Boolean(token);
    const name = window.sessionStorage.getItem('user');
    this.userName = name;
    const chooseName = window.sessionStorage.getItem('chooseName');
    this.selectName = chooseName ? chooseName : "OXO"
    if (this.isLogin) this.getDropDownItem();
  },
  watch: {
    "$route.path": function (newPath) {
      this.isHomePage = newPath === "/";
    },
  },
  methods: {
    getInitHeader() {
      var links = window.sessionStorage.getItem('links');
      if (!links) {
        this.$get("/api/v1/header-list/").then(res => {
          this.links = !this.isHomePage ? res.data : res.data.filter(item => item.text !== '返回');
          window.sessionStorage.setItem("links", JSON.stringify(res.data));
        }, err => {
          this.$msgError(err.msg);
        });
      } else {
        this.links = !this.isHomePage ? JSON.parse(links) : JSON.parse(links).filter(item => item.text !== '返回');
      }
    },
    switchswitchHandle(action, name) {
      if (name) {
        window.sessionStorage.setItem('chooseName', name);
        this.selectName = name
      }
      const actions = {
        goBack: () => this.goBack(),
        goHome: () => this.goHome(),
        toSearch: () => this.toSearch(),
        goZhFunc: () => this.goZhFunc(),
        goCnFunc: () => this.goCnFunc(),
        goAiFunc: () => this.goAiFunc(),
        goZbFunc: () => this.goZbFunc(),
        goSrFunc: () => this.goSrFunc(),
        goThFunc: () => this.goThFunc(),
        goJrFunc: () => this.goJrFunc(),
        goHsFunc: () => this.goHsFunc(),
      };
      if (actions[action]) actions[action]();
    },
    getDropDownItem() {
      this.$post("/api/v1/dropdown-list/").then(res => {
        this.dropdownItem = res.data;
      }, err => {
        this.$msgError(err.msg);
      });
    },
    switchDropHandle(action) {
      const actions = {
        personalFunc: () => this.personalFunc(),
        buyVipFunc: () => this.buyVipFunc(),
        showFormBox: () => this.showFormBox(),
        adminFunc: () => this.adminFunc(),
        logout: () => this.logout(),
        goMyCollFunc: () => this.goMyCollFunc(),
      };
      if (actions[action]) actions[action]();
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      window.sessionStorage.setItem('chooseName', "OxO");
      this.navigateTo("/");
    },
    logout() {
      window.sessionStorage.clear();
      this.$msgSuccess("退出成功 歡迎繼續訪問！")
      this.navigateTo("/login");
    },
    goToLogin() {
      this.navigateTo("/login");
    },
    personalFunc() {
      this.navigateTo("/personal");
    },
    buyVipFunc() {
      this.navigateTo("/buyVip");
    },
    showFormBox() {
      this.navigateTo("/onlineChat");
    },
    adminFunc() {
      this.$post("/api/v1/admin/check-auth/", {}).then(res => {
        // this.$msgSuccess("Verification successful");
        this.navigateTo("/admin");
      }, err => {
        this.$msgError(err.msg);
      });
    },
    toSearch() {
      window.sessionStorage.setItem('chooseName', "OxO");
      this.navigateTo("/search");
    },
    goMyCollFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isColl" } });
    },
    goZhFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isZh" } });
    },
    goCnFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isCN" } });
    },
    goAiFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isAi" } });
    },
    goZbFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isZb" } });
    },
    goSrFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isSr" } });
    },
    goThFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isTh" } });
    },
    goJrFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isJr" } });
    },
    goHsFunc() {
      this.navigateTo({ name: "allPage", params: { t: "isHs" } });
    },
  },
};
</script>




<style lang="less" scoped>
.home-container {
  height: 100%;
  width: 90%;
  margin: auto;
}

.home_font {
  font-family: PingFang SC;
}

.el-header {
  height: 50px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #a4e1ca;
  display: flex;
  justify-content: space-between;
  padding-left: 1%;
  align-items: center;
  color: #fff;
  font-size: 24px;

  >div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }
  }
}

.el-link.el-link--default {
  color: #2d6ceb;
}

.backBtnOrHome {
  margin: 0 8px;
}

.el-avatar {
  background: none;
  display: unset;
}
</style>