<template>
  <div class="paginator">
    <button class="paginator-button" :disabled="currentPage === 1" @click="changePage(currentPage - 1)">
      <
    </button>
    <div class="current-page">
      <span class="current-page-number">{{ currentPage }}</span>
    </div>
    <div class="paginator-input-container">
      <input type="number" v-model.number="enteredPage" @keyup.enter="goToPage" class="paginator-input" />
      <div class="paginator-info">共{{ total }}頁{{ totalCount }}條數據</div>
    </div>
    <button class="paginator-button" :disabled="currentPage >= total" @click="changePage(currentPage + 1)">
      >
    </button>
  </div>
</template>

<script>
export default {
  props: ["currentPage", "total", "totalCount"],
  data() {
    return {
      enteredPage: this.currentPage,
    };
  },
  watch: {
    currentPage(newPage) {
      this.enteredPage = newPage;
    },
  },
  methods: {
    goToPage() {
      if (this.enteredPage < 1) {
        this.$emit("update:currentPage", 1);
      } else if (this.enteredPage > this.total) {
        this.$emit("update:currentPage", this.total);
      } else {
        this.$emit("update:currentPage", this.enteredPage);
      }
    },
    changePage(newPage) {
      this.$emit("update:currentPage", newPage);
    },
  },
};
</script>

<style scoped>
.paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
}

.paginator-button {
  padding: 5px 10px;
  background: #a4e1ca;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease;
}

.paginator-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.paginator-button:not(:disabled):hover {
  background: #82c9a1;
}

.paginator-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.paginator-input {
  width: 50px;
  padding: 5px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.paginator-info {
  font-size: 0.9em;
  color: #666;
}

.current-page {
  font-size: 0.9em;
  color: #666;
}

.current-page-number {
  padding: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #82c9a1;
  color: #fff;
}

@media (max-width: 600px) {
  .paginator {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
</style>
