import Vue from "vue";
import VueRouter from "vue-router";

// 全局的导航守卫 修复导航到相同路由的错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

// 引入Login组件
import Login from "../components/pagesVue/loginPage/Login.vue";
// import Login from "../components/pagesVue/loginPage/loginPage.vue";
// 引入主页
import Index from "../components/pagesVue/index/index.vue";
// 单女页面
import actorPage from "../components/pagesVue/actorPage/actorPage.vue";
// 查看所有
import allPage from "../components/pagesVue/allPage/allPage.vue";
// 详情页面
// import detailsPage from "../components/pagesVue/detailsPage/detailsPage.vue";
import detailsPage from "../components/pagesVue/detailsPage/detailsPageV2.vue";
// 个人中心
import personalPage from '@/components/pagesVue/personalPage/personalPage.vue';
// 会员中心
import buyVipPage from '@/components/pagesVue/buyVipPage/buyVipPage.vue';
// 搜索页面
import searchPage from '@/components/pagesVue/searchPage/searchPage.vue';
// 在线聊天
import onlineChatPage from '@/components/pagesVue/onlineChatPage/onlineChatPage.vue';

// 后台页面
import adminPage from '@/components/pagesVue/adminPage/index.vue';
import userList from '@/components/pagesVue/adminPage/userList.vue';
import orderList from '@/components/pagesVue/adminPage/orderList.vue';
import systemList from '@/components/pagesVue/adminPage/systemList.vue';
import spiderList from '@/components/pagesVue/adminPage/spiderAuthList.vue';
import thirdPayList from '@/components/pagesVue/adminPage/thirdPartyPayList.vue';
import encryptAES from '@/components/pagesVue/adminPage/encryptAES.vue'


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/actor/:name",
    name: "actor",
    component: actorPage,
  },
  {
    path: "/all/:t",
    name: "allPage",
    component: allPage,
  },
  {
    path: "/details/:id",
    name: "details",
    component: detailsPage,
  },
  {
    path: "/personal",
    name: "personal",
    component: personalPage,
  },
  {
    path: "/buyVip",
    name: "buyVip",
    component: buyVipPage,
  },
  {
    path: "/search",
    name: "search",
    component: searchPage,
  },
  {
    path: "/onlineChat",
    name: "onlineChat",
    component: onlineChatPage,
  },
  {
    path: "/admin",
    name: "admin",
    component: adminPage,
    redirect: '/admin/userList', // 默认页面
    children: [
      {
        path: "/admin/userList",
        name: "userList",
        component: userList,
      },
      {
        path: "/admin/orderList",
        name: "orderList",
        component: orderList,
      },
      {
        path: "/admin/systemList",
        name: "systemList",
        component: systemList,
      },
      {
        path: "/admin/spiderList",
        name: "spiderList",
        component: spiderList,
      },
      {
        path: "/admin/thirdPayList",
        name: "thirdPayList",
        component: thirdPayList,
      },
      {
        path: "/admin/encryptAES",
        name: "encryptAES",
        component: encryptAES,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    // 检查用户是否已经登录
    if (window.sessionStorage.getItem("token")) {
      // 用户已登录，跳转到主页
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    // if (!window.sessionStorage.getItem('token')) {
    //   // 用户未登录，跳转到登录页面
    //   next({ path: '/login' });
    // } else {
    //   next();
    // }
    next();
  }
});

export default router; //  暴露出去
