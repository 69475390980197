import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// 自定义封装element ui
import "./plugins/element.js";
// 自定义封装接口请求
import { get, post, put } from './plugins/http.js';
// 引入全局样式
import "./assets/css/global.css";
// 引入iconfont，第三方的在main.js中引入，iconfont是矢量图标（登录界面）
import "./assets/iconfont_1/iconfont.css";

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL; // api的base_url

// Vue 启动时的生产提示
Vue.config.productionTip = false;

// 全局范围内使用这些封装的通知方法 - 调用 this.$notifySuccess(message)
Vue.prototype.$msgSuccess = function (message, title = 'success') {
  this.$notify.success({ title: title, message: message });
};
Vue.prototype.$msgError = function (message, title = 'error') {
  this.$notify.error({ title: title, message: message });
};


// copy text
Vue.prototype.$copyText = function (copyStr) {
  navigator.clipboard.writeText(copyStr).then(() => {
    this.$notify.success({ title: "success", message: "已復製內容" });
  }).catch(err => {
    console.error('Failed to copy text: ', err);
  });
};

// let windowInnerHeight = window.innerHeight;
// window.onresize = function () {
//   if (window.innerHeight < windowInnerHeight) {
//     // 窗口高度变小，可能是因为控制台被打开了
//     window.location.href = "https://baidu.com"; // 跳转到新页面
//   }
//   windowInnerHeight = window.innerHeight; // 更新窗口高度
// };

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
