<template>
    <div class="container">
        <headerVue />
        <div class="chat-window">
            <!-- 聯系人列表和消息列表 -->
            <div class="content">
                <!-- 聯系人列表 -->
                <div class="contact-list">
                    <div v-for="i in chatRoomData" class="contact" :class="{ 'selected': i.nickname === nicknameStr }">
                        <p @click="getMessageFunc(i.nickname)">{{ i.nickname }}</p>
                    </div>
                    <footerComponent />
                </div>
                <!-- 聊天消息展示和輸入框 -->
                <div class="chat-content" v-if="showMsg">
                    <div ref="messageContainer" class="message-container">
                        <div v-for="(message, index) in messages" :key="index" class="message"
                            :class="{ 'right': message.isMe, 'left': !message.isMe }">
                            <div class="message-text" :class="{ 'isMe': message.isMe, 'isCs': !message.isMe }">
                                {{ message.text }}
                            </div>
                        </div>
                    </div>
                    <!-- 输入聊天内容 -->
                    <form class="input-form" @submit.prevent="sendMessage">
                        <el-input placeholder="请输入内容" @keyup.enter.native="sendMessage" v-model="inputText"
                            class="input-with-select" clearable>
                            <el-button slot="append" icon="el-icon-s-promotion" @click="sendMessage"></el-button>
                        </el-input>
                    </form>
                </div>
                <div class="chat-content" v-else>
                    <div ref="messageContainer" class="message-container">
                        <footerComponent footerStr="點擊左側查看聊天內容" />
                    </div>
                </div>
            </div>
        </div>
        <footerComponent />
    </div>
</template>



<script>
import headerVue from "../../componentVue/headerComponent/headerComponent.vue";
import footerComponent from "../../componentVue/footerComponent/footerComponent.vue";
export default {
    components: {
        headerVue,
        footerComponent
    },
    data() {
        return {
            baseChatRoomUrl: "/api/v1/online-chat-room/",
            baseChatMsgUrl: "/api/v1/online-chat-msg/",

            chatRoomData: [], // 聊天室數據
            showMsg: false, // 是否展示聊天内容
            nicknameStr: "", // 點擊選擇的用戶名

            inputText: '',
            messages: [
                { text: '您好 歡迎使用Oo, 有什麽問題可以在此處提交! 客服並非24H在線', isMe: false },
            ],
            intervalId: null,  // 定時請求接口
        };
    },

    mounted() {
        // 創建一個定時任務，每5秒鐘請求一次getMessage
        this.intervalId = setInterval(() => {
            this.getMessageFunc(this.nicknameStr);
        }, 3000);

        this.getChatRoomFunc()
    },

    beforeDestroy() {
        // 銷毀定時任務
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    },

    updated() {
        this.$nextTick(() => {
            this.scrollToBottom();
        });
    },
    methods: {
        // 初始化獲取chat room
        getChatRoomFunc() {
            this.$get(this.baseChatRoomUrl).then(res => {
                this.chatRoomData = res.data
            }, err => {
                this.$msgError(err.msg)
            })
        },
        // 點擊在線客服 或 某一個用戶
        getMessageFunc(name) {
            this.nicknameStr = name
            this.showMsg = name ? true : false
            this.$get(this.baseChatMsgUrl, { name }).then(res => {
                this.messages = [{ text: '您好 歡迎使用Oo, 有什麽問題可以在此處提交! 客服並非24H在線', isMe: false }].concat(res.data);
            }, err => {
                this.$msgError(err.msg)
            })
        },
        // 發送最新的消息
        sendMessage(event) {
            if (event && event.type === 'keyup') {
                event.preventDefault();
            }

            if (this.inputText.trim()) {
                let isMe = window.sessionStorage.getItem("auth") >= 2 ? false : true
                this.messages.push({ text: this.inputText, isMe: isMe });
                this.$post(this.baseChatMsgUrl, { text: this.inputText, name: this.nicknameStr }).then(res => {
                }, err => {
                    this.$msgError(err.msg)
                })
                this.inputText = '';
                this.$nextTick(() => {
                    this.scrollToBottom();
                });
            }
        },

        // 數據有更新 自動滾動到最底部
        scrollToBottom() {
            const container = this.$refs.messageContainer;
            const lastMessage = container.lastElementChild;
            if (lastMessage) {
                lastMessage.scrollIntoView({ behavior: 'smooth' });
            }
        },
    },
};
</script>


<style scoped>
.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

headerVue,
footerComponent {
    height: 50px;
}

.chat-window {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
    overflow: hidden;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.contact-list {
    width: 20%;
    background: #a4e1ca;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    height: 100%;
    border-radius: 10px;
    color: #fff;
}

.contact {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.selected {
    background-color: #62c5f0;
}

.chat-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.message-container {
    background: aliceblue;
    overflow-y: auto;
    flex-grow: 1;
}

.message {
    padding: 5px;
    margin-bottom: 5px;
    clear: both;
}

.message-text {
    padding: 10px;
    border-radius: 5px;
    height: auto;
    word-break: break-word;
}

.isMe {
    background-color: lightblue;
    float: right;
}

.isCs {
    background: antiquewhite;
    float: left;
}

.input-form {
    position: sticky;
    bottom: 0;
    width: 100%;
    margin-top: 2px;
}
</style>
