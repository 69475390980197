<template>
    <div class="marquee-container">
        <div class="marquee-img">
            <img src="../../../assets/images/icons8.gif" />
        </div>
        <div class="marquee-wrapper">
            <div class="marquee-content" :style="{ transform: `translateX(${offset}px)` }">
                {{ marqueeText }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        marqueeText: {
            type: String,
            default: "禁止共享賬號 如忘記密碼 可重新註冊後 聯系客服查看",
        },
    },
    data() {
        return {
            offset: 0,
            contentWidth: 0,
            containerWidth: 0,
            animationFrame: null,
            textLength: 0,
        };
    },
    mounted() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        this.startMarquee();
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateDimensions);
        cancelAnimationFrame(this.animationFrame);
    },
    methods: {
        updateDimensions() {
            this.contentWidth = this.$el.querySelector(".marquee-content").offsetWidth;
            this.containerWidth = this.$el.querySelector(".marquee-wrapper").offsetWidth;
            this.textLength = this.marqueeText.length;
            this.offset = this.containerWidth;
        },
        startMarquee() {
            const move = () => {
                this.offset -= 1; // 向左滾動
                // 檢查是否整個文本已經滾動到空白區域
                if (this.offset + this.contentWidth <= this.containerWidth - this.textLength * 13) {
                    this.offset = this.containerWidth; // 重置offset為初始位置
                }
                this.animationFrame = requestAnimationFrame(move);
            };
            move();
        },
    },
};
</script>
<style scoped>
.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 90%;
    background-color: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
    margin: auto;
    display: flex;
    align-items: center;
}

.marquee-img>img {
    vertical-align: middle;
    border-style: none;
    width: 30px;
}

.marquee-wrapper {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    margin-left: 10px;
}

.marquee-content {
    white-space: nowrap;
    color: #333;
    font-size: 14px;
}
</style>