<template>
    <div>
        <div class="img-list" v-if="showFree == 'free'" v-loading="loading">
            <div class="img-item" v-for="(image, index) in allList " :key="index">
                <div class="img-wrapper">
                    <img :src="image.url" :alt="'Image ' + (index + 1)" @click="handleClick(image.id)"
                        @error="handleImageError" @load="handleImageLoad(image)" />
                    <div class="img-name" @click="handleClickActor(image.name)">{{ image.name }}</div>
                    <div class="vip-badge" v-if="image.isVip == 0">免費</div>
                    <div class="lang-badge" v-if="image.language == 1">中文字幕</div>
                    <div class="loading-indicator" v-if="image.isLoading"></div>
                </div>
            </div>
        </div>
        <div class="img-gallery" v-else v-loading="loading">
            <div class="img-gallery-item" v-for="(image, index) in allList" :key="index">
                <div class="blur-background" :style="{ backgroundImage: `url(${image.url})` }"></div>
                <img :src="image.url" :alt="image.name" class="image" @click="handleClick(image.id)"
                    @error="handleImageError" @load="handleImageLoad(image)" />
                <div class="img-gallery-name" @click="handleClickActor(image.name)">{{ image.name }}</div>
                <div class="vip-badge" v-if="image.isVip == 1">VIP</div>
                <div class="lang-badge" v-if="image.language == 1">中文字幕</div>
                <div class="loading-indicator" v-if="image.isLoading"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'imageList',
    props: {
        allList: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        showFree: {
            type: String,
            default: "all"
        },
        isShowMore: {
            type: Boolean,
            default: false
        },
        category: {
            type: String,
            default: null
        }
    },
    // created() {
    //     this.allList.forEach(image => {
    //         // this.$set(image, 'isLoading', true);
    //         const img = new window.Image();
    //         img.src = image.url;
    //         img.onload = () => {
    //             this.$set(image, 'isLoading', false);
    //         };
    //         img.onerror = () => {
    //             this.$set(image, 'isLoading', false);
    //         };
    //     });
    // },

    data() {
        return {
            loadedImages: []
        }
    },
    watch: {
        allList: {
            immediate: true,
            handler(newList) {
                // console.log(this.category);
                newList.forEach(image => {
                    // 如果图片已经加载过，且当前分类与图片分类相同，则不再显示加载状态
                    if (this.loadedImages.includes(image.url) && this.category === image.category) {
                        this.$set(image, 'isLoading', false);
                    } else {
                        this.$set(image, 'isLoading', true);
                        const img = new window.Image();
                        img.src = image.url;
                        img.onload = () => {
                            this.$set(image, 'isLoading', false);
                            this.loadedImages.push(image.url);
                        };
                        img.onerror = () => {
                            this.$set(image, 'isLoading', false);
                        };
                    }
                });
            }
        }
    },

    methods: {
        handleClick(id) {
            const isLoginIn = window.sessionStorage.getItem("token");
            if (isLoginIn) {
                this.$router.push({ name: "details", params: { id } });
            } else {
                this.$msgError("請先登錄");
            }
        },
        handleClickActor(name) {
            this.$router.push({ name: "actor", params: { name } });
        },
        handleClickMore(t) {
            this.$router.push({ name: "allPage", params: { t } });
        },
        handleImageError(e) {
            e.target.src = 'https://img.kankua.xyz/static/ai-ReaVBisLIKHJ8yCY/46673716584.png'; // replace with your default image url
            this.$set(e.target, 'isLoading', false);
        },
        handleImageLoad(image) {
            this.$set(image, 'isLoading', false);
        }
    }
}
</script>

<style scoped>
.loading-indicator {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 6px solid #ccc;
    border-top: 6px solid #3498db;
    /* Blue */
    animation: spin 1s linear infinite;
    background-color: rgba(255, 255, 255, 0.5);
    /* Semi-transparent white */
}

/* 图片增加背景图 且朦胧感 */
.blur-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background-size: cover;
    backdrop-filter: blur(10px);
    filter: blur(8px);
    z-index: -1;
}


@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
</style>
