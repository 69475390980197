<template>
    <div>
        <div style="margin: 5px 0;padding: 5px 0;">
            <!-- 新增账号的时候 -->
            <el-button type="text" @click="dialogFormVisibleSysFunc(true)">新增账号</el-button>
            <el-dialog title="新增账号" :visible.sync="dialogFormVisibleSys" :modal="false" v-loading="loading">
                <el-form :model="formSys">
                    <el-form-item label="商户号" :label-width="formLabelWidth">
                        <el-input v-model="formSys.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商户密钥" :label-width="formLabelWidth">
                        <el-input v-model="formSys.pwd" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="平台地址" :label-width="formLabelWidth">
                        <el-input v-model="formSys.path" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisibleSysFunc(false)">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisibleFuncSysPush()">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 编辑更新配置的时候 -->
            <el-dialog title="更新账号" :visible.sync="dialogFormVisibleSysUpdate" :modal="false" v-loading="loading">
                <el-form :model="formSysUpdate">
                    <el-form-item label="商户号" :label-width="formLabelWidth">
                        <el-input :value="formSysUpdate.mid" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="商户密钥" :label-width="formLabelWidth">
                        <el-input v-model="formSysUpdate.msecret" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="平台地址" :label-width="formLabelWidth">
                        <el-input v-model="formSysUpdate.path" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="接口地址" :label-width="formLabelWidth">
                        <el-input v-model="formSysUpdate.api_addr" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="真实账号" :label-width="formLabelWidth">
                        <el-input v-model="formSysUpdate.debug" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="账号状态" :label-width="formLabelWidth">
                        <el-input v-model="formSysUpdate.status" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisibleSysUpdateFunc">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisibleFuncSysPut">确 定</el-button>
                </div>
            </el-dialog>
        </div>

        <!-- 第三方商户列表 -->
        <el-table :data="merchantAuthData" v-loading="loading" fit>
            <el-table-column fixed prop="id" label="ID编号"></el-table-column>
            <el-table-column prop="mid" label="商户ID(加密)"></el-table-column>
            <el-table-column prop="msecret" label="商户密钥(加密)"></el-table-column>
            <el-table-column prop="name" label="第三方平台"></el-table-column>
            <el-table-column prop="path" label="第三方地址"></el-table-column>
            <el-table-column prop="debug" label="测试账号"
                :formatter="(row) => (row.debug === 1 ? '是' : '否')"></el-table-column>
            <el-table-column prop="status" label="账号状态"
                :formatter="(row) => (row.status === 1 ? '正常' : '关闭')"></el-table-column>

            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页器 -->
        <pageComponent :currentPage.sync="currentPage" :total="total" :totalCount="totalCount" />
    </div>
</template>

<script>
import pageComponent from "../../componentVue/pageComponent/pageComponent.vue";
export default {
    components: {
        pageComponent,
    },
    data() {
        return {
            basePath: "api/v1/admin/third-party-pay-list/",
            loading: true,
            totalCount: 0,
            total: 0,
            currentPage: 1,
            searchSysVal: "", // 搜索
            merchantAuthData: [], // 商户账号列表

            dialogFormVisibleSys: false, // 新增
            formLabelWidth: "120px",
            formSys: {
                name: '',
                pwd: '',
                path: ''
            },

            dialogFormVisibleSysUpdate: false, // 更新
            formSysUpdate: {},


        }
    },

    created() {
        this.getMerchantAuthData(this.currentPage);
    },
    watch: {
        currentPage(newPage) {
            this.getMerchantAuthData(newPage);
        },
    },

    methods: {
        // 初始化获取数据
        getMerchantAuthData(page) {
            this.loading = true
            this.$get(this.basePath, {
                pageNum: page,
                search: this.searchUserVal,
            }).then(
                (res) => {
                    this.merchantAuthData = res.data;
                    this.totalCount = res.count;
                    this.total = res.total;
                    this.loading = false;
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },

        // 第三方账号-新增
        dialogFormVisibleSysFunc(showTK) {
            this.formSys = { isVip: 0 }
            this.dialogFormVisibleSys = showTK
        },
        // 第三方账号-确认
        dialogFormVisibleFuncSysPush() {
            this.loading = true
            this.$post(this.basePath, this.formSys).then(
                (res) => {
                    this.$msgSuccess()
                    this.getMerchantAuthData()
                    this.formSys = { isVip: 0 }
                    this.dialogFormVisibleSys = false
                },
                (err) => {
                    this.$msgError(err.msg);
                }
            );
        },


        // 点击编辑
        handleEdit(scope, row) {
            this.formSysUpdate = row
            this.dialogFormVisibleSysUpdate = true
        },
        // 取消编辑
        dialogFormVisibleSysUpdateFunc() {
            this.dialogFormVisibleSysUpdate = false
        },
        // 确认提交
        dialogFormVisibleFuncSysPut() {
            this.loading = true
            this.$put(this.basePath, this.formSysUpdate).then(res => {
                this.$msgSuccess()
                this.getMerchantAuthData(this.currentPage)
                this.dialogFormVisibleSysUpdateFunc()
            }, err => {
                this.$msgError(err.msg)
            })
        },

    }
}
</script>
